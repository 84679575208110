import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Enviroment } from './env';

@Injectable({
  providedIn: 'root'
})
export class TercerosService {

  basicHeaders: any;
  token: string;
  IdEmpresa: string;
  body: any;

  constructor(private http: HttpClient) {
    this.token = sessionStorage.getItem('token');
    this.IdEmpresa = JSON.parse(sessionStorage.getItem('IdEmpresa'))

    this.basicHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${this.token}`
    }
    )
  }

/* 
  Consecutivo_Pacientes({ IdEmpresa }): Promise<any> {
    const body = { IdEmpresa }
    return this.http.post<any>(Enviroment.url_backend + 'ConsecutivoPacientes', body, { headers: this.basicHeaders }).toPromise();
  }

  Cargar_Terceros(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'ListarTerceros', body, { headers: this.basicHeaders }).toPromise();
  }

  Cargar_Establecimientos(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'ListarEstablecimientos', body, { headers: this.basicHeaders }).toPromise();
  }

  Listar_Odo_Paciente(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'ListarOdoPaciente', body, { headers: this.basicHeaders }).toPromise();
  } */

  getPermisosNG(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + '/authentication/ListarPermisosNg', body, { headers: this.basicHeaders }).toPromise();
  }

  getMunicipios(): Promise<any> {
    return this.http.get<any>(Enviroment.url_backend + '/third-parties/ListarMunicipios', { headers: this.basicHeaders }).toPromise();
  }

  Listar_Terceros(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + '/third-parties/ListarTerceros', body, { headers: this.basicHeaders }).toPromise();
  }

}
