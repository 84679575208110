import { Component, OnInit } from '@angular/core';
import { SocketWebService } from '../../services/socket-web.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  
  constructor(private _socketService: SocketWebService) {  }

  ngOnInit() {  }

  ngOnDestroy() {  }

}
