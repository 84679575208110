import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Listar_Precio_Garantias } from 'src/app/shared/models/invoice';
import { InvoiceService } from 'src/app/shared/services/invoice.service';
import Swal from 'sweetalert2';
import { Listar_MunicipiosRoot, Listar_Municipios, Listar_Terceros, Listar_TercerosRoot } from '../../../shared/models/third-parties';
import { TercerosService } from '../../../shared/services/terceros.service'

@Component({
  selector: 'app-garantias',
  templateUrl: './garantias.component.html',
  styleUrls: ['./garantias.component.scss']
})
export class GarantiasComponent implements OnInit {

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  IdEmpresa: string;
  IdSucursal: string;
  Cargando: string;
  isloading: boolean;

  keywordciudad: string;
  keywordCliente: string;

  arrCiudades: Listar_Municipios[];
  arrClientes: Listar_Terceros[];
  arrProductos: Listar_Precio_Garantias[];
  arrTempProductos: Listar_Precio_Garantias[];

  constructor(private _TercerosServices: TercerosService, private _InvoiceServices: InvoiceService) {

    this.IdEmpresa = JSON.parse(sessionStorage.getItem('IdEmpresa'));
    this.IdSucursal = JSON.parse(sessionStorage.getItem('IdSucursal'));

    this.keywordciudad = 'name';
    this.keywordCliente = 'Nombre';
  }

  async ngOnInit() {
    try {
      await this.Cargar_Municipios();
    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "NO se realizo el proceso comunicate con el administrador",
        icon: 'error'
      });
    }
  }

  async Cargar_Municipios() {
    try {
      this.Cargando = 'Block';

      await this._TercerosServices.getMunicipios().then(data => {
        this.Cargando = 'none';
        this.arrCiudades = data.result;
      })

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "NO se realizo el proceso comunicate con el administrador",
        icon: 'error'
      });
    }
  }

  async Cargar_Terceros(IdCiudad: number) {
    try {
      this.Cargando = 'Block';

      await this._TercerosServices.Listar_Terceros({
        IdEmpresa: this.IdEmpresa,
        IdSucursal: this.IdSucursal,
        IdCiudad: IdCiudad,
        IdSql: 1
      }).then(data => {
        this.Cargando = 'none';
        this.arrClientes = data.result;
      })

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "NO se realizo el proceso comunicate con el administrador",
        icon: 'error'
      });
    }
  }

  async Cargar_Productos_Precios(IdCliente: number) {
    try {
      this.Cargando = 'Block';

      await this._InvoiceServices.Listar_Precio_Garantias({
        IdEmpresa: this.IdEmpresa,
        IdSucursal: this.IdSucursal,
        IdCliente: IdCliente
      }).then(data => {
        this.Cargando = 'none';
        this.arrProductos = data.result;
        this.arrTempProductos = [...data.result]
      })

    
    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "Error",
        html: ' <div class="well well-sm ContenedorGris"><p> ' + error + ' </p></div>',
        icon: 'error'
      });
    }
  }

  async selectEventCiudad($event) {
    try {
      await this.Cargar_Terceros($event.id);
    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "NO se realizo el proceso comunicate con el administrador",
        icon: 'error'
      });
    }

  }

  onFocusedCiudad($event) {

  }

  onChangeSearchCiudad($event) {

  }

  async selectEventCliente($event) {
    try {
      await this.Cargar_Productos_Precios($event.id);
    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "Error",
        html: ' <div class="well well-sm ContenedorGris"><p> ' + error + ' </p></div>',
        icon: 'error'
      });
    }
  }

  onFocusedCliente($event) {

  }

  onChangeSearchCliente($event) {

  }

  updateFilterProduc(event){
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.arrTempProductos.filter(function (d) {
      return d.ChrNombreProdu.toLowerCase().indexOf(val) !== -1 || !val;
    });
    // update the rows    
    this.arrProductos = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    //recarga cuando no tiene nada  
  }

}
