<!-- Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body custom-datatable">
                    <input type='text' class="filter-ngx form-control" placeholder='Nombre inventario...'
                        (keyup)='updateFilter($event)' />
                    <ngx-datatable #table class='bootstrap' [columnMode]="ColumnMode.force"
                        [headerHeight]="50" [footerHeight]="50" rowHeight="34" [limit]="10" [rows]="rows">

                        <ngx-datatable-column name="Referencia" prop="ChrRefeProdu"></ngx-datatable-column>
                        <ngx-datatable-column name="Nombre" prop="ChrNombreProdu"></ngx-datatable-column>                       
                        <ngx-datatable-column  name="Existencia" prop="IntExitenciaInven"></ngx-datatable-column>

                    </ngx-datatable>
                </div>
            </div>
        </div>
    </div>

    <div class="spinnerBackdrop" [ngStyle]="{'display': Cargando }">
        <div class="spinnerContent">
            <div class="spinnerItem">
                <div class="line bg-primary"></div>
                <div class="line bg-primary"></div>
                <div class="line bg-primary"></div>
                <div class="line bg-primary"></div>
            </div>
            <div class="MensajeDeEspera">Cargando...!!!</div>
        </div>
    </div>

</div>
<!-- Container-fluid Ends -->