<!-- Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 pt-3">
            <div class="card">
                <div class="card-header">
                    <h5>Garantias</h5>
                </div>

                <div class="card-body">
                    <div class="row">

                        <div class="col-md-3 col-sm-3">
                            <label class="col-form-label pt-0">*Ciudad:</label>
                            <div class="ng-autocomplete">
                                <ng-autocomplete [data]="arrCiudades" [searchKeyword]="keywordciudad"
                                    placeholder="Seleciona Ciudad" (selected)='selectEventCiudad($event)'
                                    (inputChanged)='onChangeSearchCiudad($event)'
                                    (inputFocused)='onFocusedCiudad($event)' [itemTemplate]="itemTemplate"
                                    [notFoundTemplate]="notFoundTemplate" [isLoading]='isloading'>
                                </ng-autocomplete>

                                <ng-template #itemTemplate let-item>
                                    <a [innerHTML]="item.name"></a>
                                </ng-template>

                                <ng-template #notFoundTemplate let-notFound>
                                    <div [innerHTML]="NoResulstados"></div>
                                </ng-template>
                            </div>
                        </div>

                        <div class="col-md-3 col-sm-3">
                            <label class="col-form-label pt-0">*Cliente:</label>
                            <div class="ng-autocomplete">
                                <ng-autocomplete [data]="arrClientes" [searchKeyword]="keywordCliente"
                                    placeholder="Seleciona Cliente" (selected)='selectEventCliente($event)'
                                    (inputChanged)='onChangeSearchCliente($event)'
                                    (inputFocused)='onFocusedCliente($event)' [itemTemplate]="itemTemplateCliente"
                                    [notFoundTemplate]="notFoundTemplate" [isLoading]='isloading'>
                                </ng-autocomplete>

                                <ng-template #itemTemplateCliente let-item>
                                    <a [innerHTML]="item.Nombre"></a>
                                </ng-template>

                                <ng-template #notFoundTemplate let-notFound>
                                    <div [innerHTML]="NoResulstados"></div>
                                </ng-template>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3 col-sm-3">
                            <label class="col-form-label pt-0">*Filtro Producto:</label>
                            <input type='text' class="form-control" placeholder='Filtrar Producto'
                                (keyup)='updateFilterProduc($event)' />
                        </div>
                    </div>
                    <div class="row">
                        <div class="card-body custom-datatable">
                            <ngx-datatable class="bootstrap" [rows]="arrProductos" [columnMode]="'force'"
                                [headerHeight]="'auto'" [footerHeight]="'auto'" [rowHeight]="'auto'" [limit]="10"
                                [scrollbarH]="true">

                                <ngx-datatable-column name="Producto" prop="ChrNombreProdu"></ngx-datatable-column>
                                <ngx-datatable-column width="60" name="Lote" prop="ChrLotes"></ngx-datatable-column>

                                <ngx-datatable-column width="100" name="Precio" prop="IntValorFact">
                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                        {{row.IntValorFact | number}}
                                    </ng-template>
                                </ngx-datatable-column>

                                <ngx-datatable-column width="80" name="Fecha" prop="Fecha">
                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                        {{row.Fecha}}
                                    </ng-template>
                                </ngx-datatable-column>

                                <ngx-datatable-column width="60" name="Cantidad" prop="Cantidad"></ngx-datatable-column>
                                <ngx-datatable-column width="60" name="Factura" prop="FV"></ngx-datatable-column>


                            </ngx-datatable>

                        </div>
                    </div>
                </div>

                <div class="spinnerBackdrop" [ngStyle]="{'display': Cargando }">
                    <div class="spinnerContent">
                        <div class="spinnerItem">
                            <div class="line bg-primary"></div>
                            <div class="line bg-primary"></div>
                            <div class="line bg-primary"></div>
                            <div class="line bg-primary"></div>
                        </div>
                        <div class="MensajeDeEspera">Cargando...!!!</div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- Container-fluid Ends -->