<!-- Page Header Start-->
<div class="page-main-header" [ngClass]="{'open' : navServices.collapseSidebar}" id="page-main-header">
  <div class="main-header-right row">
    <div class="main-header-left d-lg-none">
      <div class="logo-wrapper"><a href="javascript::void(0)"><img width="110" height="90"
        src={{logo}} alt=""></a></div>
    </div>
    <div class="mobile-sidebar">
      <div class="media-body text-right switch-sm">
        <label class="switch">
          <a href="javascript:void(0)" (click)="collapseSidebar()">
            <app-feather-icons id="sidebar-toggle" [icon]="'align-left'"></app-feather-icons>
          </a>
        </label>
      </div>
    </div>
    <div class="nav-right col">
      <ul class="nav-menus" [ngClass]="{'open' : openNav}">
        <li>
          <!-- <form class="form-inline search-form">
            <div class="form-group"> -->
              <!-- <input class="form-control-plaintext" type="text" autocomplete="off" placeholder="Buscar.."> -->
              <!-- <span class="d-sm-none mobile-search">
                <app-feather-icons [icon]="'search'"></app-feather-icons>
              </span> -->
            <!-- </div>
          </form> -->
        </li>

        <!-- =====================================================================
          ///////////   Este es su botón de Pago Abierto ePayco   ///////////
         ===================================================================== -->
        <form id="frm_ePaycoCheckoutOpen" name="frm_ePaycoCheckoutOpen" method="POST"
          action="https://secure.payco.co/checkoutopen.php">

          <input name="p_cust_id_cliente" type="hidden" value="50256" />
          <input name="p_key" type="hidden" value="e91411d8384df71ec151cc124695665487346249" />
          <input name="p_id_factura" type="hidden" value="" />
          <input name="p_description" type="hidden" value="Servicios de AdentLab (Servicios de AdentLab (Todo lo relacionado con AdentLab, Servicio, Factura Electronica, Etiquetas, Impresoras, Escaner, ETC))" />
          <input name="p_detalle" type="hidden"
            value="" />
          <input name="p_referencia" type="hidden" value="" />
          <input name="p_test_request" type="hidden" value="false" />
          <input name="p_url_respuesta" type="hidden" value="" />
          <input name="p_url_confirmacion" type="hidden" value="" />
          <input type="image" id="imagen" src="https://multimedia.epayco.co/dashboard/btns/btn1.png" alt="" (click)="abripaguina($event)" />
          <input type="hidden" id="idboton" name="idboton" value="46432" />

        </form> <!-- ================================================================== -->

        <li>
          <a href="javascript:void(0)" class="text-dark" toggleFullscreen>
            <app-feather-icons [icon]="'maximize'"></app-feather-icons>
          </a>
        </li>
        <li class="onhover-dropdown">
          <a class="txt-dark" href="javascript:void(0)">
            <h6>EN</h6>
          </a>
          <ul class="language-dropdown onhover-show-div p-20">
            <li><a href="javascript:void(0)" data-lng="en"><i class="flag-icon flag-icon-is"></i> English</a></li>
            <li><a href="javascript:void(0)" data-lng="es"><i class="flag-icon flag-icon-um"></i> Spanish</a></li>
            <li><a href="javascript:void(0)" data-lng="pt"><i class="flag-icon flag-icon-uy"></i> Portuguese</a></li>
            <li><a href="javascript:void(0)" data-lng="fr"><i class="flag-icon flag-icon-nz"></i> French</a></li>
          </ul>
        </li>
        <li class="onhover-dropdown">
          <app-feather-icons [icon]="'bell'"></app-feather-icons><span class="dot"></span>
          <ul class="notification-dropdown onhover-show-div">
            <li>Notification <span class="badge badge-pill badge-primary pull-right">3</span></li>
            <li>
              <div class="media">
                <div class="media-body">
                  <h6 class="mt-0"><span>
                      <app-feather-icons class="shopping-color" [icon]="'shopping-bag'"></app-feather-icons>
                    </span>Your order ready for Ship..!<small class="pull-right">9:00 AM</small></h6>
                  <p class="mb-0">Lorem ipsum dolor sit amet, consectetuer.</p>
                </div>
              </div>
            </li>
            <li>
              <div class="media">
                <div class="media-body">
                  <h6 class="mt-0 txt-success"><span>
                      <app-feather-icons class="download-color font-success" [icon]="'download'"></app-feather-icons>
                    </span>Download Complete<small class="pull-right">2:30 PM</small></h6>
                  <p class="mb-0">Lorem ipsum dolor sit amet, consectetuer.</p>
                </div>
              </div>
            </li>
            <li>
              <div class="media">
                <div class="media-body">
                  <h6 class="mt-0 txt-danger"><span>
                      <app-feather-icons class="alert-color font-danger" [icon]="'alert-circle'"></app-feather-icons>
                    </span>250 MB trush files<small class="pull-right">5:00 PM</small></h6>
                  <p class="mb-0">Lorem ipsum dolor sit amet, consectetuer.</p>
                </div>
              </div>
            </li>
            <li class="bg-light txt-dark"><a href="javascript:void(0)">All</a> notification</li>
          </ul>
        </li>
        <li><a>
            <app-feather-icons class="right_side_toggle" [icon]="'message-circle'">
            </app-feather-icons><span class="dot"></span>
          </a></li>
        <li class="onhover-dropdown">
          <div class="media align-items-center"><img class="align-self-center pull-right img-50 rounded-circle"
              src={{urlImagen}} alt="header-user">
            <div class="dotted-animation"><span class="animate-circle"></span><span class="main-circle"></span></div>
          </div>
          <ul class="profile-dropdown onhover-show-div p-20">
            <li><a href="javascript:void(0)">
                <app-feather-icons [icon]="'user'"></app-feather-icons>Editar Perfil
              </a></li>
            <li><a href="javascript:void(0)">
                <app-feather-icons [icon]="'mail'"></app-feather-icons>Bandeja Entrada
              </a></li>
            <li><a href="javascript:void(0)">
                <app-feather-icons [icon]="'lock'"></app-feather-icons>Bloquer Pantalla
              </a></li>
            <li><a href="javascript:void(0)">
                <app-feather-icons [icon]="'settings'"></app-feather-icons>Configuracion
              </a></li>
            <li><a href="login">
                <app-feather-icons [icon]="'log-out'"></app-feather-icons>Salida Segura
              </a></li>
          </ul>
        </li>
      </ul>
      <div class="d-lg-none mobile-toggle pull-right">
        <app-feather-icons [icon]="'more-horizontal'"></app-feather-icons>
      </div>
    </div>
  </div>
</div>
<!-- Page Header Ends -->