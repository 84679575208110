<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>ACUSE DE RECIBIDO</h5>
                </div>
            </div>

            <div class="card">
                <div class="row">
                    <div class="col-md-6 col-xl-3 xl-40 upload-space-sm">
                        Agregar XML:
                        <input class="btn btn-primary btn-sm btn-pill" type="file" ng2FileSelect [uploader]="uploader"
                            accept="application/xml" multiple /><br />
                    </div>
                    <div class="col-xl-9 col-md-6 xl-60 upload-table">
                        <p>Cantidad de Archivos: {{ uploader?.queue?.length }}</p>
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th width="50%">Nombre</th>
                                        <th>Estatus</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of uploader.queue">
                                        <td><strong>{{ item?.file?.name }}</strong></td>
                                        <td *ngIf="uploader.isHTML5">
                                            <div class="progress mb-0">
                                                <div class="progress-bar" role="progressbar"
                                                    [ngStyle]="{ 'width': item.progress + '%' }"></div>
                                            </div>
                                        </td>
                                        <td>
                                            <span *ngIf="item.isSuccess"><i class="fa fa-check-square-o"></i></span>
                                            <span *ngIf="item.isError"><i class="fa fa-trash"></i></span>
                                        </td>
                                        <td nowrap>
                                            <button type="button" class="btn btn-success btn-xs mr-1"
                                                (click)="item.upload()"
                                                [disabled]="item.isReady || item.isUploading || item.isSuccess">
                                                <span class="fa fa-upload"></span> Cargar
                                            </button>
                                            <button type="button" class="btn btn-danger btn-xs" (click)="item.remove()">
                                                <span class="fa fa-trash"></span> Remover
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <div class="fill-progress">
                                Procesando..!!:
                                <div class="progress">
                                    <div class="progress-bar" role="progressbar"
                                        [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
                                </div>
                            </div>
                            <button type="button" class="btn btn-success btn-s mr-1" (click)="uploader.uploadAll()"
                                [disabled]="!uploader.getNotUploadedItems().length">
                                <span class="fa fa-upload"></span> Cargar Todos
                            </button>
                            <button type="button" class="btn btn-danger btn-s" (click)="uploader.clearQueue()"
                                [disabled]="!uploader.queue.length">
                                <span class="fa fa-trash"></span> Remover Todos
                            </button>

                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;


                            <button type="button" class="btn btn-primary btn-s mr-1" (click)="saveAll()"
                                [disabled]="btnguardar">
                                <span class="fa fa-save"></span> Guardar Todo
                            </button>

                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="row"> 
            
                    <div class="card-body custom-datatable">
                        <div class="form-group">
                            <input type='text' class="filter-ngx form-control" placeholder='Buscar Proveedor...!!!'
                                (keyup)='updateFilteseller($event)' />
                        </div>

                        <ngx-datatable class="bootstrap" [rows]="arrrecive_documents" [columnMode]="'force'"
                            [headerHeight]="50" [footerHeight]="50"  [rowHeight]="'auto'"  [limit]="10" [scrollbarH]="true">

                            <ngx-datatable-column [width]="90" name="Nit" prop="ChrNit" [cellClass]="getCellClass"></ngx-datatable-column>
                            <ngx-datatable-column [width]="200" name="Proveedor" prop="chrname_seller">
                            </ngx-datatable-column>
                            <ngx-datatable-column [width]="80" name="Prefijo" prop="chprefix"></ngx-datatable-column>
                            <ngx-datatable-column [width]="80" name="Numero" prop="chrnumber"></ngx-datatable-column>

                            <ngx-datatable-column [width]="100" name="Fecha" prop="date_issue">
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    {{row.date_issue | date: 'mediumDate'}}
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column [width]="250" name="XML" prop="chrnamexml"></ngx-datatable-column>

                            <ngx-datatable-column [width]="150" name="Total" prop="dbltotal">
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    {{row.dbltotal | currency: 'COP'}}
                                </ng-template>
                            </ngx-datatable-column>


                            <ngx-datatable-column [width]="90" name="Ver Dian" prop="acu_recibo">
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    <div class="btn-group" role="group">
                                        <button (click)="ver_Dian(row)" class="btnverdian">
                                            <i class="fa fa-file-text-o"></i></button>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column [width]="90" name="Acuse Recibido" prop="acu_recibo">
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    <div class="btn-group" role="group">
                                        <button *ngIf="row.acu_recibo" class="btnenviadoacuse">
                                            <i class="fa fa-check"></i></button>
                                        <button *ngIf="!row.acu_recibo" (click)="acuse_recibido(row)"
                                            class="btnenviaracuse">
                                            <i class="fa fa-paper-plane"></i></button>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column [width]="90" name="Recibo Mercancia" prop="rec_bienes">
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    <div class="btn-group" role="group">
                                        <button *ngIf="row.rec_bienes" class="btnenviadoacuse">
                                            <i class="fa fa-check"></i></button>
                                        <button *ngIf="!row.rec_bienes && row.acu_recibo"
                                            (click)="recibo_mercancia(row)" class="btnenviaracuse">
                                            <i class="fa fa-paper-plane"></i></button>
                                        <button *ngIf="!row.acu_recibo" class="btnnoenviadoacuse">
                                            <i class="fa fa-ban"></i></button>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column [width]="90" name="Aceptacion" prop="aceptacion">
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    <div class="btn-group" role="group">
                                        <button *ngIf="row.aceptacion" class="btnenviadoacuse">
                                            <i class="fa fa-check"></i></button>
                                        <button *ngIf="!row.aceptacion && row.rec_bienes && !row.rechazo"
                                            (click)="aceptacion(row)" class="btnenviaracuse">
                                            <i class="fa fa-paper-plane"></i></button>
                                        <button *ngIf="!row.rec_bienes || row.rechazo  " class="btnnoenviadoacuse">
                                            <i class="fa fa-ban"></i></button>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column [width]="90" name="Rechazo" prop="rechazo">
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    <div class="btn-group" role="group">
                                        <button *ngIf="row.rechazo" class="btnenviadoacuse">
                                            <i class="fa fa-check"></i></button>
                                        <button *ngIf="!row.rechazo && row.rec_bienes && !row.aceptacion"
                                            (click)="rechazo(row)" class="btnenviaracuse">
                                            <i class="fa fa-paper-plane"></i></button>
                                        <button *ngIf="!row.rec_bienes || row.aceptacion" class="btnnoenviadoacuse">
                                            <i class="fa fa-ban"></i></button>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>


                        </ngx-datatable>

                    </div>
                </div> 
            </div>

        </div>

        <div class="spinnerBackdrop" [ngStyle]="{'display': Cargando }">
            <div class="spinnerContent">
                <div class="spinnerItem">
                    <div class="line bg-primary"></div>
                    <div class="line bg-primary"></div>
                    <div class="line bg-primary"></div>
                    <div class="line bg-primary"></div>
                </div>
                <div class="MensajeDeEspera">Cargando...!!!</div>
            </div>
        </div>


    </div>
</div>
<!-- Container-fluid Ends -->