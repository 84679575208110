import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticatorService } from '../../shared/services/authenticator.service';
import { UserService } from '../../shared/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(
    public authService: AuthenticatorService,
    public router: Router,
    public userService: UserService) { }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // Guard for user is login or not
    //let user = JSON.parse(localStorage.getItem('user'));
    if (!this.userService.user) {
      this.authService.ngDeleteSession()
      this.router.navigate(['login']);
      return true
    }
    return true
  }
}
