<div class="page-wrapper">
	<div class="auth-bg">
		<div class="authentication-box">
			<div class="text-center"><img width="196" height="160" src="assets/images/imagen1.png" alt=""></div>
			<div class="card mt-4">
				<div class="card-body">
					<div class="text-center">
						<h4>LOGIN</h4>
						<h6>Entra tu usuario y contraseña </h6>
					</div>
					<form class="theme-form" [formGroup]="loginForm" *ngIf="!newUser">
						<div class="form-group">
							<label class="col-form-label pt-0">Usuario</label>
							<input class="form-control" formControlName="user" type="text" required="">
							<div *ngIf="loginForm.controls.user.touched && loginForm.controls.user.errors?.required"
								class="text text-danger mt-1">
								Usuario es Requerido
							</div>
							<div *ngIf="loginForm.controls.user.touched && loginForm.controls.user.errors?.user"
								class="text text-danger mt-1">
								Usuario Invalido
							</div>
						</div>
						<div class="form-group">
							<label class="col-form-label">Contraseña</label>
							<input class="form-control" type="password" formControlName="password" required="">
							<div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required"
								class="text text-danger mt-1">
								Contraseña es Requerido
							</div>
						</div>
						<div class="checkbox p-0">
							<input id="checkbox1" type="checkbox">
							<label for="checkbox1">Recuerdame</label>
						</div>
						<div class="form-group form-row mt-3 mb-0">
							<button *ngIf="!entrando"  class="btn btn-primary btn-block btn-lg" [disabled]="!loginForm.valid" (click)="login(loginForm.value)"
								type="submit">Entrar
							</button>
							<button *ngIf="entrando"  class="btn btn-primary btn-block btn-lg" disabled type="submit"> Entrando...!!
							</button>
						</div>
						<div class="login-divider"></div>
						<div class="social mt-3">
							<div class="form-group btn-showcase d-flex">
								<button class="btn social-btn btn-fb d-inline-block" (click)="loginFacebook()" >
									<i class="fa fa-facebook"></i>
								</button>
								<button class="btn social-btn btn-twitter d-inline-block" (click)="loginTwitter()">
									<i class="fa fa-twitter"></i>
								</button>
								<button class="btn social-btn btn-google d-inline-block" (click)="loginGoogle()">
									<i class="fa fa-google"></i>
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>