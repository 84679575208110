export class User {
    constructor(public BlnActivoUsuari?,
        public ChrNombreCompleto?,
        public idEmpreGeneral?,
        public idTblUsuarios?,
        public Fk_Terceros?,
        public urlimagen?,
        public ChrUsuarios?)
         
        { }
}