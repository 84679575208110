import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Enviroment } from './env';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ListarSeleEmpresa, ListarSeleEmpresaRoot } from '../models/listarSelecEmpresas'

@Injectable({
  providedIn: 'root'
})
export class ListarEmpresasService {

  listarEmpresas: ListarSeleEmpresaRoot
  basicHeaders: any;
  token: string;
  idUsuario: string

  constructor(private http: HttpClient) {
    this.token = sessionStorage.getItem('token');
    this.idUsuario = JSON.parse(sessionStorage.getItem('idUsuario'))

    this.basicHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${this.token}`
    })
  }

  getEntreprise(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'ListarEmpresas', body, { headers: this.basicHeaders }).toPromise();
  }

  getAll({ IntUsuario }): Promise<ListarSeleEmpresaRoot> {
    const body = { IntUsuario }
    return this.http.post<ListarSeleEmpresaRoot>(Enviroment.url_backend + '/company/ListarSeleEmpresa', body, { headers: this.basicHeaders }).toPromise();
  }

}