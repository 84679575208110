import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Enviroment } from './env';

@Injectable({
  providedIn: 'root'
})
export class ApidianService {

  basicHeaders: any;
  token: string;

  constructor(private http: HttpClient) {
    
    this.token = sessionStorage.getItem('ApiDian');   

    this.basicHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'accept': 'application/json',
      'Authorization': `Bearer ${this.token}`,
    })
  }

  sendevent(body): Promise<any> {   
    return this.http.post<any>(Enviroment.url_apidian + 'send-event', body, { headers: this.basicHeaders }).toPromise();
  }



}
