<!-- Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 col-xl-12">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-md-4 col-sm-3">
                                <input type='text' class="filter-ngx form-control" placeholder='Nombre cliente...'
                                    (keyup)='updateFilter($event)' />
                            </div>

                            <div class="col-md-4 col-sm-3">
                                <input type='text' class="filter-ngx form-control" placeholder='Nombre ciudad...'
                                    (keyup)='updateFilterCiudad($event)' />
                            </div>

                            <div class="col-md-4 col-sm-3">
                                <label for="">Valor de cartera: </label> <b> <label for="">{{valorcartera | number}}</label></b>
                            </div>
                        </div>
                    </div>
                    <div class="card-body custom-datatable">
                        <ngx-datatable #table class='bootstrap' [columnMode]="'force'" [headerHeight]="'auto'"
                            [footerHeight]="'auto'" [rowHeight]="'auto'" [limit]="20" [rows]="rows" [scrollbarH]="true">

                            <ngx-datatable-column width="60" name="No Factura" prop="IntIdFactura">
                            </ngx-datatable-column>
                            <ngx-datatable-column width="120" name="Fecha" prop="DtmEpxFac">
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    {{row.DtmEpxFac | date: 'mediumDate'}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column width="40" name="Dias" prop="Dias"></ngx-datatable-column>
                            <ngx-datatable-column name="Cliente" prop="ChrNombre"></ngx-datatable-column>
                            <ngx-datatable-column width="100" name="Saldo" prop="IntSaldoFact">
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    {{row.IntSaldoFact | number}}
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column width="120" name="Ciudad" prop="NombPaciente"></ngx-datatable-column>

                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="spinnerBackdrop" [ngStyle]="{'display': Cargando }">
        <div class="spinnerContent">
            <div class="spinnerItem">
                <div class="line bg-primary"></div>
                <div class="line bg-primary"></div>
                <div class="line bg-primary"></div>
                <div class="line bg-primary"></div>
            </div>
            <div class="MensajeDeEspera">Cargando...!!!</div>
        </div>
    </div>

</div>
<!-- Container-fluid Ends -->