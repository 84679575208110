import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terceros',
  templateUrl: './terceros.component.html',
  styleUrls: ['./terceros.component.scss']
})
export class TercerosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
