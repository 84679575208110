import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Enviroment } from './env';
import 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})

export class CarteraService {
  
  basicHeaders: any;
  basicHeadersCloud: any;
  token: string;

  constructor(private http: HttpClient) {
    this.token = sessionStorage.getItem('token');

    this.basicHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${this.token}`
    })
  }

  Listar_Cartera(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + '/report/RptFacturasGeneradas', body, { headers: this.basicHeaders }).toPromise();
  }

}