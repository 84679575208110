import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Enviroment } from './env';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import jwtDecode, * as jwt_decode from 'jwt-decode';
import { UserService } from './user.service';
import { Licencia } from '../models/licencia';
import { ListarLoginHttp } from '../models/listarLogin'
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatorService {

  token: string;
  basicHeaders: any;
  user!: User

  constructor(private http: HttpClient, private userService: UserService) {
    this.token = sessionStorage.getItem('token');
    this.ngsetHeaders(this.token);

    this.basicHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${this.token}`})
  }

  ngLogin(body) {
    return this.http.post(Enviroment.url_backend + '/authentication/login', body, { headers: this.basicHeaders }).pipe((map((response: any) => {      
      this.ngSaveData(response.result.token);
      return response;
    })), catchError((error => {
      return throwError(error)
    })))
  }

  ngSaveData(token) {
    this.token = token
    sessionStorage.setItem('token', token);

    this.user =  this.userService.deserializeUser(this.userService.decodeTokenToUser(this.token))    
    sessionStorage.setItem('idUsuario', this.user.idTblUsuarios)
    sessionStorage.setItem('ChrNombreCompleto', this.user.ChrNombreCompleto)
    sessionStorage.setItem('urlimagen', this.user.urlimagen)    
  }

  decodeTokenToLicence() {
    let decoded: Licencia = jwtDecode(this.token);
    return decoded.user.ListarLogin
  }

  ngsetHeaders(token) {
    return this.basicHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${token}`
    })
  }

  ngDeleteSession(){
    localStorage.clear()
    this.userService.user = null
  }

  renewToken({user}): Promise<any>{
    const body = {user}
    return this.http.post<any>(Enviroment.url_backend + 'renew-token', body, { headers: this.basicHeaders }).toPromise();
  }
}
