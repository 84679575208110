import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import Swal from 'sweetalert2';
import { InventarioService } from '../../shared/services/inventario.service'
import { Listar_InventarioRoot, Listar_Inventario } from '../../shared/models/inventario'

@Component({
  selector: 'app-inventarios',
  templateUrl: './inventarios.component.html',
  styleUrls: ['./inventarios.component.scss']
})
export class InventariosComponent implements OnInit {

  rows: Listar_Inventario[];
  temp: Listar_Inventario[];
  Cargando: string;
  IdEmpresa: string;
  IdSucursal: string;

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  ColumnMode = ColumnMode;

  constructor(private inventarioService: InventarioService) { 

    this.IdEmpresa = JSON.parse(sessionStorage.getItem('IdEmpresa'));
    this.IdSucursal = JSON.parse(sessionStorage.getItem('IdSucursal'));

  }

  ngOnInit(): void {
    this.cargar_Inventarios();
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function (d) {
      return d.ChrNombreProdu.toLowerCase().indexOf(val) !== -1 || !val;
    });

    this.rows = temp;
    this.table.offset = 0;
  }

  async cargar_Inventarios(){
    try {
      this.Cargando = 'Block';

      const res: Listar_InventarioRoot = await this.inventarioService.Listar_Inventario(
        {
          IdEmpresa: this.IdEmpresa,
          IdSucursal: this.IdSucursal,
          Tipo: 1,
          NombreProd: '',
          Refe: ''
        })

      this.temp = [...res.result]
      this.rows = res.result;

      this.Cargando = 'none';

    } catch (error) {
      Swal.fire({
        title: "No se realizo el proceso..!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error: </label><br /><p>' + error + '</p></div>', icon: 'error',
      });
    }
  }

}
