import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpClient, HttpHeaders, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Enviroment } from './env';
import { Observable, throwError } from 'rxjs';
import jwtDecode from 'jwt-decode';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';


@Injectable({
    providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

    token: string;
    basicHeaders: any;
    user: any;
    tokendecode: any;
    horaactual: number;

    isrenew = false;

    constructor(private http: HttpClient, private router: Router) {
        this.basicHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': ``
        })
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let request = req;

        /* this.user = sessionStorage.getItem('ChrUsuarios');
        if (this.user !== null) {

            this.token = sessionStorage.getItem('token');
            this.tokendecode = jwtDecode(this.token)
            this.horaactual = Math.floor(Date.now() / 1000)

            if ((this.tokendecode.exp - this.horaactual) < 50) {

                this.ngRenewToken({ user: this.user }).subscribe((resp: any) => {
                    console.log('entro');
                }, error => {
                    console.log(error);
                });
            }
        } */
        return next.handle(request).pipe(
            catchError((err: HttpErrorResponse) => {

                if (err.status === 401) {
                    this.router.navigate(['login']);
                }

                return throwError(err);

            })
        );
    }



   /*  ngRenewToken({ user }) {
        const body = { user };
        return this.http.post(Enviroment.url_backend + 'renew-token', body, { headers: this.basicHeaders }).pipe((map((response: any) => {
            sessionStorage.setItem('token', response.token);
            this.token = sessionStorage.getItem('token');
            this.tokendecode = jwtDecode(this.token)
            return response;
        })), catchError((error => {
            return throwError(error)
        }))) */

    //}

}

