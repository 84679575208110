import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthenticatorService } from '../../../shared/services/authenticator.service';
import { ToastrService } from 'ngx-toastr'

type UserFields = 'user' | 'password';
type FormErrors = { [u in UserFields]: string };

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public newUser = false;
  public loginForm: FormGroup;
  public errorMessage: any;
  entrando: any;

  constructor(
    public authenticatorService: AuthenticatorService,
    private toastaService: ToastrService,
    private fb: FormBuilder,
    private router: Router) {
    this.loginForm = this.fb.group({
      user: ['', [Validators.required]],
      password: ['', Validators.required]
    });

  }

  ngOnInit() {
    this.entrando = false;
    sessionStorage.clear();
    localStorage.clear();
  }

  login() {
    //this.authService.SignIn(this.loginForm.value['email'], this.loginForm.value['password']);
    this.entrando = true;   
    this.authenticatorService.ngLogin(this.loginForm.value).subscribe((res: any) => {
      this.router.navigate(['/listarempresas'])
    }, error => {
      this.entrando = false;
      this.toastaService.warning(error.error.mesage, 'Lo sentimos', { timeOut: 3000 });
    });

  }

}
