import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Enviroment } from './env';
import 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  basicHeaders: any;
  basicHeadersCloud: any;
  token: string;

  constructor(private http: HttpClient) {
    this.token = sessionStorage.getItem('token');

    this.basicHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${this.token}`
    })
  }

  getAllFactGeneradas(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + '/invoice-listings/ListarFacturasGeneradas', body, { headers: this.basicHeaders }).toPromise();
  }

  UpdateDateInovice(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + 'ActualizarFechas', body, { headers: this.basicHeaders }).toPromise();
  }

  get_received_documents(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + '/received-document/get_received_documents', body, { headers: this.basicHeaders }).toPromise();
  }

  insert_received_documents(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + '/received-document/insert_received_documents', body, { headers: this.basicHeaders }).toPromise();
  }

  update_received_documents(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + '/received-document/update_received_documents', body, { headers: this.basicHeaders }).toPromise();
  }

  delete_cloudinary(body): Promise<any> {
      this.basicHeadersCloud = new HttpHeaders({
      'Content-Type': 'application/json',
    })
     return this.http.post<any>('https://api.cloudinary.com/v1_1/adentlab/delete_by_token', body, { headers: this.basicHeadersCloud }).toPromise();
  }
  
  Listar_Precio_Garantias(body): Promise<any> {
    return this.http.post<any>(Enviroment.url_backend + '/invoices/ListarPrecioGarantias', body, { headers: this.basicHeaders }).toPromise();
  }

}
