import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import Swal from 'sweetalert2';
import { Lista_CarteraRoot, Lista_Cartera } from '../../../shared/models/cartera'
import { CarteraService } from '../../../shared/services/cartera.service'

@Component({
  selector: 'app-cartera',
  templateUrl: './cartera.component.html',
  styleUrls: ['./cartera.component.scss']
})
export class CarteraComponent implements OnInit {

  rows: Lista_Cartera[];
  temp: Lista_Cartera[];
  Cargando: string;
  IdEmpresa: string;
  IdSucursal: string;
  valorcartera: number;
  IdUsuario: string;

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  ColumnMode = ColumnMode;

  constructor(private carteraService: CarteraService) {

    this.IdEmpresa = JSON.parse(sessionStorage.getItem('IdEmpresa'));
    this.IdSucursal = JSON.parse(sessionStorage.getItem('IdSucursal'));
    this.IdUsuario = JSON.parse(sessionStorage.getItem('idUsuario'));
    this.valorcartera = 0
  }

  async ngOnInit(): Promise<void> {
     await this.cargar_Cartera_General();        
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function (d) {
      return d.ChrNombre.toLowerCase().indexOf(val) !== -1 || !val;
    });

    this.rows = temp;
    this.valorcartera = this.rows.map(item => item.IntSaldoFact).reduce((prev, curr) => prev + curr, 0);
    this.table.offset = 0;
  }

  updateFilterCiudad(event) {
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function (d) {
      return d.NombPaciente.toLowerCase().indexOf(val) !== -1 || !val;
    });

    this.rows = temp;
    this.valorcartera = this.rows.map(item => item.IntSaldoFact).reduce((prev, curr) => prev + curr, 0);
    this.table.offset = 0;
  }

  async cargar_Cartera_General() {
    try {
      this.Cargando = 'Block';

      const res: Lista_CarteraRoot = await this.carteraService.Listar_Cartera(
        {
          Desde: "2021/01/01",
          Hasta: "2021/01/01",
          IdEmpresa: this.IdEmpresa,
          IdBusqueda: 7,
          IdTercero: 0,
          IdCiudad: 0
        })

      this.temp = [...res.result]
      this.rows = res.result;

      this.valorcartera = this.rows.map(item => item.IntSaldoFact).reduce((prev, curr) => prev + curr, 0);

      this.Cargando = 'none';

    } catch (error) {

      Swal.fire({
        title: "No se realizo el proceso..!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error: </label><br /><p>' + error + '</p></div>', icon: 'error',
      });
    }
  }

}

